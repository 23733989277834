import { RouteRecordRaw } from "vue-router"
import { InternalRootView } from "@/routes/utils/route.utils"
import { ChannelLabel, ChannelName, getRoote } from "./cc.v1.utils"
import { ICON_SVG } from "@/routes/icon.svg"
import { ApplyRoutes } from "./credit.card.apply.v1.route"
import { OrderRoutes } from "./credit.card.order.v1.route"
import { ReconRoutes } from './credit.card.reconciliation.v1.route';

export const LXBCCV1Route: RouteRecordRaw = {
    path: getRoote(),
    name: ChannelLabel,
    component: InternalRootView,
    meta: {
        label: ChannelLabel,
        icon: 'TrendCharts',
        group: { name: ChannelName, label: ChannelLabel },
        href: (d: any) => {
            return '/'
        },
        showInLeftMenu: true,
        svg: ICON_SVG.home
    },
    children: [
        ...ApplyRoutes,
        ...OrderRoutes,
        ...ReconRoutes
    ]
}