import { getComponent, getRewardPerformanceRoote } from "./cc.v2.utils"

export const ChannelPath = 'lxb/performance'
export const ChannelLabel = '业绩奖励'

export const PerformanceRoutes = [
    {
        name: getRewardPerformanceRoote('batch/list'),
        path: getRewardPerformanceRoote('batch/list'),
        component: getComponent('CreditCardPerformanceBatchV2List'),
        meta: {
            label: '业绩结算',
            icon: 'Search',
            group: { name: ChannelPath, label: ChannelLabel },
            showInLeftMenu: true,
            tabAutoClose: false
        },
    },
    {
        name: getRewardPerformanceRoote('batch/settlement'),
        path: getRewardPerformanceRoote('batch/settlement'),
        component: getComponent('CreditCardPerformanceBatchV2Detail'),
        meta: {
            label: '结算详情',
            icon: 'Search',
            group: { name: ChannelPath, label: ChannelLabel },
            auto:false,
            showInLeftMenu: false
        },
    },
    {
        name: getRewardPerformanceRoote('list'),
        path: getRewardPerformanceRoote('list'),
        component: getComponent('CreditCardPerformanceV2List'),
        meta: {
            label: '业绩奖励',
            icon: 'Search',
            group: { name: ChannelPath, label: ChannelLabel },
            showInLeftMenu: true,
            tabAutoClose: false
        },
    },
    {
        name: getRewardPerformanceRoote(':year/:month'),
        path: getRewardPerformanceRoote(':year/:month'),
        component: getComponent('CreditCardPerformanceV2List'),
        meta: {
            label: '业绩奖励',
            icon: 'Search',
            group: { name: ChannelPath, label: ChannelLabel },
            auto: false,
            showInLeftMenu: false
        },
    },
    {
        name: getRewardPerformanceRoote(`detail`),
        path: getRewardPerformanceRoote(`:id`),
        component: getComponent('CreditCardPerformanceV2Detail'),
        meta: {
            label: '详情',
            icon: 'Search',
            group: { name: ChannelPath, label: ChannelLabel },
            auto: false,
            showInLeftMenu: false
        }
    }
]