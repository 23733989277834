import { getComponent, getReconRoote } from './cc.v1.utils'
import { ICON_SVG } from '@/routes/icon.svg'

export const ChannelName = 'lxb/creditcard/reconciliation/v1'
export const ChannelLabel = '信用卡开卡导入(1.0)'

export const ReconRoutes = [
    {
        name: getReconRoote('flow'),
        path: getReconRoote('flow'),
        component: getComponent('ReconciliationV1Flow'),
        meta: {
            label: '银行对账',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: false,
            href: (d: any) => {
                return `/${ChannelName}`
            },
            svg: ICON_SVG.bank
        },
    },
    {
        name: getReconRoote('flow/detail'),
        path: getReconRoote(`flow/:id`),
        component: getComponent('ReconciliationV1Record'),
        meta: {
            label: '列表',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: false,
            auto:false,
            href: (d: any) => {
                return `/${ChannelName}`
            },
            svg: ICON_SVG.bank
        },
    },
    {
        name: getReconRoote('import'),
        path: getReconRoote(`import`),
        component: getComponent('ReconciliationV1Import'),
        meta: {
            label: '导入',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: false,
            auto: false,
            href: (d: any) => {
                return `/${ChannelName}/condition/${d.id}`
            },
            svg: ICON_SVG.bank
        }
    },
    {        
        name: getReconRoote('records/detail'),
        path: getReconRoote(`record/:id`),
        component: getComponent('ReconciliationV1RecordDetail'),
        meta: {
            label: '详情',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: false,
            auto: false,
            href: (d: any) => {
                return `/${ChannelName}/condition/${d.id}`
            },
            svg: ICON_SVG.bank
        }
    }
]