import { ICON_SVG } from '@/routes/icon.svg'
import { getComponent, getOrderRoote } from './cc.v1.utils'

export const ChannelName = getOrderRoote()
export const ChannelLabel = '推卡订单'

export const OrderRoutes = [{
    name: getOrderRoote('list'),
    path: getOrderRoote('list'),
    component: getComponent('CreditCardOrderV1List'),
    meta: {
        label: '推卡订单',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
            return `/${ChannelName}`
        },
        svg: ICON_SVG.bank
    },
},
    {
        name: getOrderRoote('detail'),
        path: getOrderRoote(`:id`),
        component: getComponent('CreditCardOrderV1Detail'),
        meta: {
            label: '详情',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: false,
            auto:false,
            href: (d: any) => {
                return `/${ChannelName}/condition/${d.id}`
            },
            svg: ICON_SVG.bank
        }
    }]
