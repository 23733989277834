import { ICON_SVG } from './icon.svg'
import { RouteRecordRaw } from 'vue-router'
import { InternalRootView, InternalSubView, component } from './utils/route.utils'

export const ChannelName = 'dashboard'
export const ChannelLabel = '首页'
export const HomeRoute: RouteRecordRaw = {
  path: '/',
  name: 'home',
  component: InternalRootView,
  meta: {
    label: '仪表盘',
    icon: 'Histogram',
    group: { name: ChannelName, label: ChannelLabel },
    href: (d: any) => {
      return '/'
    },
    showInLeftMenu: true,
  },
  children: [
    {
      name: 'home-sub',
      path: '/',
      component: component(ChannelName, 'HomeView'),
      meta: {
        label: '查询',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: true,
        svg: ICON_SVG.goods
      }
    }
  ]
}
