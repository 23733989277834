import { ICON_SVG } from '../../../icon.svg'
import { getRewardLevelRoote, getComponent } from './cc.v2.utils'

export const ChannelName = getRewardLevelRoote()
export const ChannelLabel = '等级设置'

export const RewardLevelRoutes = [
  {
    name: getRewardLevelRoote('list'),
    path: getRewardLevelRoote('list'),
    component: getComponent('CreditCardRewardLevelV2Tabs'),
    meta: {
      label: '等级奖励设置',
      icon: 'Search',
      group: { name: ChannelName, label: ChannelLabel },
      showInLeftMenu: false,
      href: (d: any) => {
        return `/${ChannelName}`
      },
      svg: ICON_SVG.bank
    },
  },
  {
    name: getRewardLevelRoote('type'),
    path: getRewardLevelRoote(':id'),
    component: getComponent('CreditCardRewardLevelV2Tabs'),
    meta: {
      label: '列表',
      icon: 'Search',
      group: { name: ChannelName, label: ChannelLabel },
      auto: false,
      showInLeftMenu: false,
      href: (d: any) => {
        return `/${ChannelName}`
      },
      svg: ICON_SVG.bank
    },
  },
  {
    name: getRewardLevelRoote('edit'),
    path: getRewardLevelRoote(`/level/:id`),
    component: getComponent('CreditCardRewardLevelV2LevelDetail'),
    meta: {
      label: '编辑',
      icon: 'Search',
      group: { name: ChannelName, label: ChannelLabel },
      auto: false,
      showInLeftMenu: false,
      href: (d: any) => {
        return `/${ChannelName}/${d.id}`
      },
      svg: ICON_SVG.bank
    }
  },
  {
    name: getRewardLevelRoote('detail'),
    path: getRewardLevelRoote(`condition/:id`),
    component: getComponent('CreditCardRewardLevelV2RewardDetail'),
    meta: {
      label: '活动详情',
      icon: 'Search',
      group: { name: ChannelName, label: ChannelLabel },
      auto: false,
      showInLeftMenu: false,
      href: (d: any) => {
        return `/${ChannelName}/condition/${d.id}`
      },
      svg: ICON_SVG.bank
    }
  }
]