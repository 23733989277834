import { component } from "@/routes/utils/route.utils"

export const ChannelName = 'lxb/creditcard/v2'
export const ChannelLabel = '信用推卡(2.0)'

export const getRoote = (key?: string) => {
    if (key === undefined) {
        return `/${ChannelName}`
    }
    return `/${ChannelName}/${key}`
}

export const getComponent = (name: string) => {
    return component(`${ChannelName}`, name)
}

export const getApplyRoote = (key?: string) => {
    if (key === undefined) {
        return getRoote('apply')
    }
    return getRoote('apply/' + key)
}

export const getOrderRoote = (key?: string) => {
    if (key === undefined) {
        return getRoote('order')
    }
    return getRoote('order/' + key)
}

export const getReconRoote = (key?: string) => {
    if (key === undefined) {
        return getRoote('recon')
    }
    return getRoote('recon/' + key)
}

export const getRewardTrainRoote = (key?: string) => {
    if (key === undefined) {
        return getRoote('reward/train')
    }
    return getRoote('reward/train/' + key)
}

export const getRewardMentorRoote = (key?: string) => {
    if (key === undefined) {
        return getRoote('reward/mentor')
    }
    return getRoote('reward/mentor/' + key)
}

export const getRewardTeam300Roote = (key?: string) => {
    if (key === undefined) {
        return getRoote('reward/team/300')
    }
    return getRoote('reward/team/300/' + key)
}

export const getRewardLevelRoote = (key?: string) => {
    if (key === undefined) {
        return getRoote('reward/level')
    }
    return getRoote('reward/level/' + key)
}

export const getRewardPerformanceRoote = (key?: string) => {
    if (key === undefined) {
        return getRoote('reward/performance')
    }
    return getRoote('reward/performance/' + key)
}