import { component } from '../utils/route.utils'

import { ICON_SVG } from '../icon.svg'
import { RouteRecordRaw } from 'vue-router'

export const ChannelName = 'lxb/withdraw'
export const ChannelLabel = '会员提现'

const componentPath = (path?: string) => {
    if (path === undefined || path === null) {
        return ChannelName
    }

    return `${ChannelName}/${path}`
}

export const WithdrawRoute: RouteRecordRaw = {
    name: ChannelName,
    path: `/${ChannelName}`,
    meta: {
        label: '会员提现',
        icon: 'CreditCard',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: true,
        svg: ICON_SVG.bank
    },
    children: [
        {
            name: '/${ChannelName}',
            path: `/${ChannelName}`,
            component: component(componentPath(), 'WithdrawList'),
            meta: {
                label: '列表',
                icon: 'Search',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false,
                svg: ICON_SVG.bank,
                tabAutoClose: false
            },
        },
        {
            name: '/${ChannelName}/detail',
            path: `/${ChannelName}/:id`,
            component: component(componentPath(), 'WithdrawEdit'),
            meta: {
                label: '详情',
                icon: 'Search',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false,
                svg: ICON_SVG.bank
            }
        }
    ]
}
