import { getComponent, getReconRoote } from './cc.v2.utils'
import { ICON_SVG } from '@/routes/icon.svg'

export const ChannelName = getReconRoote()
export const ChannelLabel = '银行对账'

export const ReconRoutes = [
    {
        name: getReconRoote('flow'),
        path: getReconRoote('flow'),
        component: getComponent('CreditCardReconV2Flow'),
        meta: {
            label: '银行对账',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: false,
            href: (d: any) => {
                return `/${ChannelName}`
            },
            svg: ICON_SVG.bank,
            tabAutoClose: false
        },
    },
    {
        name: getReconRoote('flow/detail'),
        path: getReconRoote(`flow/:id`),
        component: getComponent('CreditCardReconV2List'),
        meta: {
            label: '列表',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            auto:false,
            showInLeftMenu: false,
            href: (d: any) => {
                return `/${ChannelName}`
            },
            svg: ICON_SVG.bank
        },
    },
    {
        name: getReconRoote('import'),
        path: getReconRoote('import'),
        component: getComponent('CreditCardReconV2Import'),
        meta: {
            label: '导入',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            auto: false,
            showInLeftMenu: false,
            href: (d: any) => {
                return `/${ChannelName}/condition/${d.id}`
            },
            svg: ICON_SVG.bank
        }
    },
    {
        name: getReconRoote('record'),
        path: getReconRoote('record/:id'),
        component: getComponent('CreditCardReconV2RecordDetail'),
        meta: {
            label: '详情',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            auto: false,
            showInLeftMenu: false,
            href: (d: any) => {
                return `/${ChannelName}/condition/${d.id}`
            },
            svg: ICON_SVG.bank
        }
    }
]
