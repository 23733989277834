import { ICON_SVG } from '@/routes/icon.svg'
import { component } from '@/routes/utils/route.utils'
import { RouteRecordRaw } from 'vue-router'

export const ChannelName = 'lxb/creditcard/commission'
export const ChannelLabel = '信用卡'

const componentPath = (path?: string) => {
    if (path === undefined || path === null) {
        return ChannelName
    }

    return `${ChannelName}/${path}`
}

export const CreditCardCommissionRoute: RouteRecordRaw = {
    name: ChannelName,
    path: `/${ChannelName}`,
    meta: {
        label: '信用卡管理',
        icon: 'CreditCard',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: true,
        href: (d: any) => {
            return `/${ChannelName}/0`
        },
        svg: ICON_SVG.bank
    },
    children: [
        {
            name: 'credit-card-reward',
            path: `/${ChannelName}/reward/`,
            component: component(componentPath(), 'CreditCardCommissionList'),
            meta: {
                label: '结算方式',
                icon: 'Search',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false,
                href: (d: any) => {
                    return `/${ChannelName}`
                },
                svg: ICON_SVG.bank,
                tabAutoClose: false
            },
        },
        {
            name: 'credit-card-reward-new',
            path: `/${ChannelName}/reward/new`,
            component: component(componentPath(), 'CreditCardCommission'),
            meta: {
                label: '新增结算方式',
                icon: 'Search',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false,
                keepAlive: false,
                href: (d: any) => {
                    return `/${ChannelName}/${d.id}`
                },
                svg: ICON_SVG.bank
            }
        },
        {
            name: 'credit-card-reward-edit',
            path: `/${ChannelName}/reward/:id`,
            component: component(componentPath(), 'CreditCardCommission'),
            meta: {
                label: '编辑奖励',
                icon: 'Search',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false,
                keepAlive: false,
                href: (d: any) => {
                    return `/${ChannelName}/${d.id}`
                },
                svg: ICON_SVG.bank
            }
        },
        {
            name: 'credit-card-type-list',
            path: `/${ChannelName}/type/`,
            component: component(componentPath(), 'CreditCardTypeList'),
            meta: {
                label: '卡种管理',
                icon: 'Search',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false,
                href: (d: any) => {
                    return `/${ChannelName}/${d.parentId}`
                },
                svg: ICON_SVG.bank,
                tabAutoClose: false
            },
        },
        {
            name: 'credit-card-type-new',
            path: `/${ChannelName}/type/new`,
            component: component(componentPath(), 'CreditCardType'),
            meta: {
                label: '新增卡种',
                icon: 'DocumentAdd',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false,
                href: (d: any) => {
                    return `/${ChannelName}`
                },
                svg: ICON_SVG.bank
            }
        },
        {
            name: 'credit-card-type-edit',
            path: `/${ChannelName}/type/:id`,
            component: component(componentPath(), 'CreditCardType'),
            meta: {
                label: '编辑卡种',
                icon: 'DocumentAdd',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false,
                href: (d: any) => {
                    return `/${ChannelName}`
                },
                svg: ICON_SVG.bank
            }
        }
    ]
}
