import { InternalSubView, InternalRootView, component } from '../utils/route.utils'

import { ICON_SVG } from '../icon.svg'
import { RouteRecordRaw } from 'vue-router'

export const ChannelName = 'product/bank'
export const ChannelLabel = '银行'
export const BankRoute: RouteRecordRaw = {
  name: 'bank',
  path: `/${ChannelName}`,
  meta: {
    label: '银行管理',
    icon: 'OfficeBuilding',
    group: { name: ChannelName, label: ChannelLabel },
    showInLeftMenu: true,
    href: (d: any) => {
      return '/bank'
    },
    svg: ICON_SVG.bank
  },
  children: [
    {
      name: 'bank-search',
      path: `/${ChannelName}`,
      component: component(ChannelName, 'BankListView'),
      meta: {
        label: '查询',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          return `/${ChannelName}`
        },
        svg: ICON_SVG.bank
      }
    },
    {
      path: `/${ChannelName}/new`,
      name: 'bank-new',
      component: component(ChannelName, 'BankDetailView'),
      meta: {
        label: '新增',
        icon: 'DocumentAdd',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          if (d === undefined) {
            return '/bank'
          }

          return `/ bank / ${d.params.id} `
        },
        svg: ICON_SVG.product
      }
    },
    {
      path: `/${ChannelName}/:bankId(\\d+)`,
      name: 'bank-edit',
      component: component(ChannelName, 'BankDetailView'),
      meta: {
        label: '详情',
        icon: 'DocumentChecked',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        href: (d: any) => {
          if (d === undefined) {
            return '/bank'
          }

          return `/ bank / ${d.bankId} `
        },
        svg: ICON_SVG.product
      }
    }
  ]
}
