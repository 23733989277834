import { ICON_SVG } from '@/routes/icon.svg'
import { getComponent, getRewardTrainRoote } from './cc.v2.utils'

export const ChannelName = getRewardTrainRoote()
export const ChannelLabel = '培养奖'

export const RewardTrainRoutes = [
    {
        name: getRewardTrainRoote('list'),
        path: getRewardTrainRoote('list'),
        component: getComponent('CreditCardOtherRewardTrainV2List'),
        meta: {
            label: '培养奖',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: false,
            href: (d: any) => {
                return `/${ChannelName}`
            },
            svg: ICON_SVG.bank
        },
    },
    {
        name: getRewardTrainRoote('detail'),
        path: getRewardTrainRoote(`:id`),
        component: getComponent('CreditCardMentorV2Detail'),
        meta: {
            label: '详情',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            auto: false,
            showInLeftMenu: false,
            href: (d: any) => {
                return `/${ChannelName}/condition/${d.id}`
            },
            svg: ICON_SVG.bank
        }
    }
]
