import { getApplyRoote, getComponent } from './cc.v1.utils'

export const ChannelName = getApplyRoote()
export const ChannelLabel = '办卡申请'

export const ApplyRoutes = [{
    name: getApplyRoote('list'),
    path: getApplyRoote('list'),
    component: getComponent('CreditCardApplyV1List'),
    meta: {
        label: '办卡申请',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: true
    },
},
{
    name: getApplyRoote('detail'),
    path: getApplyRoote(':id'),
    component: getComponent('CreditCardApplyV1Detail'),
    meta: {
        label: '详情',
        icon: 'Search',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: false,
        auto: false
    }
},]