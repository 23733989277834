import { LoginRoute } from './access/login.route'
import { RouteRecordRaw } from 'vue-router'
import { UserRoute } from './access/user.route'
import { MyRoute } from './access/my.route'
import { HomeRoute } from './home.route'
import { ProductRoute } from './product'
import { CreditCardCommissionRoute } from './lxb/cc/credit.card.commission.route'
import { CCChannelOrderRoutes } from './lxb/cc/channel/credit.card.channel.order.route'
import { InternalRootView } from './utils/route.utils'
import { MemberRoute } from './lxb/member.route'
import { WithdrawRoute } from './lxb/withdraw.route'
import { ShareTemplateRoute } from './dfq/share.template.route'
import { AdsBannerRoute } from './dfq/ads.banner.route'
import { NoticeRoute } from './lxb/notice.route'
import { LXBCCV1Route } from './lxb/cc/v1/cc.v1.routes';
import { LXBCCV2Route } from './lxb/cc/v2/cc.v2.routes'
import { PosterRoute } from './lxb/poster.route'

const routes = [
  HomeRoute,
  ProductRoute,
  CreditCardCommissionRoute,
  CCChannelOrderRoutes,
  LXBCCV1Route,
  LXBCCV2Route,
  WithdrawRoute,
  MemberRoute,
  PosterRoute,
  ShareTemplateRoute,
  AdsBannerRoute,
  NoticeRoute,
  UserRoute,
  MyRoute
]

routes.forEach(v => {
  v.component = InternalRootView
  v.children?.forEach(child => {
    if (!child.path.includes(':') && !child.path.includes('/new')) {
      if (child && child.meta && child.meta.auto===undefined) {
        child.meta.showInLeftMenu = true
      }
    }
  })
})

export const GlobalRoutes: RouteRecordRaw[] = [LoginRoute, ...routes]
