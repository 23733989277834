import { ICON_SVG } from '../icon.svg'
import { RouteRecordRaw } from 'vue-router'
import { InternalRootView, InternalSubView, component } from '../utils/route.utils'
import { BankRoute } from './bank.route'

export const ChannelName = 'product'
export const ChannelLabel = '产品管理'
export const ProductRoute: RouteRecordRaw = {
  path: '/product',
  name: '产品管理',
  component: InternalRootView,
  meta: {
    label: '产品管理',
    icon: 'GoodsFilled',
    group: { name: ChannelName, label: ChannelLabel },
    href: (d: any) => {
      return '/'
    },
    showInLeftMenu: true
  },
  children: [BankRoute]
}
