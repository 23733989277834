import { getComponent, getOrderRoote } from './cc.v2.utils'

import { ICON_SVG } from '@/routes/icon.svg'

export const ChannelName = getOrderRoote()
export const ChannelLabel = '信用卡订单管理(2.0)'

export const OrderRoutes = [
    {
        name: getOrderRoote('list'),
        path: getOrderRoote('list'),
        component: getComponent('CreditCardOrderV2List'),
        meta: {
            label: '订单列表',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: false,
            href: (d: any) => {
                return `/${ChannelName}`
            },
            svg: ICON_SVG.bank,
            tabAutoClose: false
        },
    },
    {
        name: getOrderRoote('detail'),
        path: getOrderRoote(`:id`),
        component: getComponent('CreditCardOrderV2Detail'),
        meta: {
            label: '详情',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: false,
            auto: false,
            href: (d: any) => {
                return `/${ChannelName}/condition/${d.id}`
            },
            svg: ICON_SVG.bank
        }
    }
]

