export const LoginView = () => import("../../views/LoginView.vue")
export const InternalRootView = () => import("../../views/InternalView.vue")
export const InternalSubView = () => import("../../views/RouteView.vue")

const comps = import.meta.glob('/src/components/**/*.vue')
export const component = (path: string, component: string) => {
    console.log('load component: ', path, component)
    const comp_name = `/src/components/${path}/${component}.vue`
    const comp = comps[comp_name]
    if (comp === undefined || comp === null) {
        console.error(`component[${comp_name}] is`, comp, path, component)
    }

    return comp
}