import { component } from '../utils/route.utils'

import { RouteRecordRaw } from 'vue-router'

export const ChannelName = 'lxb/member'
export const ChannelLabel = '会员管理'

const componentPath = (path?: string) => {
    if (path === undefined || path === null) {
        return ChannelName
    }

    return `${ChannelName}/${path}`
}

export const MemberRoute: RouteRecordRaw = {
    name: ChannelName,
    path: `/${ChannelName}`,
    meta: {
        label: '会员管理',
        icon: 'CreditCard',
        group: { name: ChannelName, label: ChannelLabel },
        showInLeftMenu: true
    },
    children: [
        {
            name: 'lxb/member/list',
            path: `/${ChannelName}`,
            component: component(componentPath(), 'List'),
            meta: {
                label: '列表',
                icon: 'Search',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false,
                tabAutoClose: false
            },
        },
        {
            name: 'lxb/member/detail',
            path: `/${ChannelName}/:id`,
            component: component(componentPath(), 'Detail'),
            meta: {
                label: '详情',
                icon: 'Search',
                group: { name: ChannelName, label: ChannelLabel },
                showInLeftMenu: false
            }
        }
    ]
}
