import { ICON_SVG } from '@/routes/icon.svg'
import { getComponent, getRewardTeam300Roote } from './cc.v2.utils'

export const ChannelName = getRewardTeam300Roote()
export const ChannelLabel = '城市经理奖'

export const RewardTeam300Routes = [
    {
        name: getRewardTeam300Roote('list'),
        path: getRewardTeam300Roote('list'),
        component: getComponent('CreditCardOtherRewardCityManagerV2List'),
        meta: {
            label: '城市经理奖',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: false,
            href: (d: any) => {
                return `/${ChannelName}`
            },
            svg: ICON_SVG.bank,
            tabAutoClose: false
        },
    },
    {
        name: getRewardTeam300Roote('detail'),
        path: getRewardTeam300Roote(':id'),
        component: getComponent('CreditCardOtherRewardCityManagerV2Detail'),
        meta: {
            label: '详情',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            auto: false,
            showInLeftMenu: false,
            href: (d: any) => {
                return `/${ChannelName}/condition/${d.id}`
            },
            svg: ICON_SVG.bank
        }
    }
]