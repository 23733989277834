
import { getApplyRoote, getComponent } from './cc.v2.utils'

export const ChannelName = getApplyRoote()
export const ChannelLabel = '办卡申请'

export const ApplyRoutes = [
    {
        name: getApplyRoote('list'),
        path: getApplyRoote('list'),
        component: getComponent('CreditCardApplyV2List'),
        meta: {
            label: '办卡申请',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: true,
            auto: true,
            tabAutoClose: false
        },
    },
    {
        name: getApplyRoote('detail'),
        path: getApplyRoote(`:id`),
        component: getComponent('CreditCardApplyV2Detail'),
        meta: {
            label: '详情',
            icon: 'Search',
            group: { name: ChannelName, label: ChannelLabel },
            showInLeftMenu: false,
        }
    }
]