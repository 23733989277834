import { component } from "@/routes/utils/route.utils"

export const ChannelName = 'lxb/creditcard/v1'
export const ChannelLabel = '信用推卡(1.0)'

export const getRoote = (key?: string) => {
    if (key === undefined) {
        return `/${ChannelName}`
    }
    return `/${ChannelName}/${key}`
}

export const getComponent = (name: string) => {
    return component(`${ChannelName}`, name)
}

export const getApplyRoote = (key?: string) => {
    if (key === undefined) {
        return getRoote('apply')
    }
    return getRoote('apply/' + key)
}

export const getOrderRoote = (key?: string) => {
    if (key === undefined) {
        return getRoote('order')
    }
    return getRoote('order/' + key)
}

export const getReconRoote = (key?: string) => {
    if (key === undefined) {
        return getRoote('recon')
    }
    return getRoote('recon/' + key)
}