import { RouteRecordRaw } from "vue-router"
import { InternalRootView } from "@/routes/utils/route.utils"
import { ChannelLabel, ChannelName, getRoote } from "./cc.v2.utils"
import { ICON_SVG } from "@/routes/icon.svg"
import { ApplyRoutes } from "./credit.card.apply.v2.route"
import { OrderRoutes } from "./credit.card.order.v2.route"
import { ReconRoutes } from './credit.card.reconciliation.v2.route';
import { RewardMentorRoutes } from "./credit.card.reward.v2.mentor.route"
import { RewardTeam300Routes } from "./credit.card.reward.v2.citymanager.route"
import { RewardLevelRoutes } from './credit.card.reward.v2.level.route';
import { PerformanceRoutes } from "./credit.card.performance.v2.route"
import { RewardTrainRoutes } from "./credit.card.reward.v2.train.route"

export const LXBCCV2Route: RouteRecordRaw = {
    path: getRoote(),
    name: ChannelLabel,
    component: InternalRootView,
    meta: {
        label: ChannelLabel,
        icon: 'TrendCharts',
        group: { name: ChannelName, label: ChannelLabel },
        href: (d: any) => {
            return '/'
        },
        showInLeftMenu: true,
        svg: ICON_SVG.home
    },
    children: [
        ...ApplyRoutes,
        ...ReconRoutes,
        ...OrderRoutes,
        ...PerformanceRoutes,
        ...RewardMentorRoutes,
        ...RewardTeam300Routes,
        ...RewardTrainRoutes,
        ...RewardLevelRoutes,
    ]
}